<template>
  <div>
    <van-sticky :offset-top="0">
      <demo-block :title="('')">
        <van-nav-bar :title="('充值查询')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
          <template #right>
            <van-icon name="home" size="18" />
          </template>
        </van-nav-bar>
      </demo-block>
    </van-sticky>
    <van-tabs v-model:active="active" sticky>
      <van-pull-refresh
            v-model="isLoading"
            success-text="刷新成功"
            @refresh="onRefresh"
          >
        <van-tab v-for="shop in ordertype" :title="shop.title" :key="shop.id">
          <demo-block>
          

            <van-cell-group v-for="i in lscurorder" :key="i.id" style="margin-bottom:20px;margin-top:5px;" inset>
              <van-cell :title="i.accdscrp" :value="i.opfare" >
                <template #title>
                  <van-tag type="primary" size="large">{{i.accdscrp}}
                    
                  </van-tag>
                  <van-icon :name="require('../../../assets/images/notecase' + i.notecase + '.png')" color="#9f0000" size="20" style="margin-bottom:-5px;margin-left:20px;margin-top:5px;"/>
                </template>
                <template #value>
                  <van-tag type="warning"  size="large">{{i.opfare}}</van-tag>                
                </template>

              </van-cell>
              <van-cell title="充值位置" :value="i.termname"/>
              <van-cell title="充值时间" :value="i.opdt"  />
            </van-cell-group>
          </demo-block>
        </van-tab>
      </van-pull-refresh>
        <van-button v-if="finished===false" :loading="isLoading" plain hairline type="primary" block @click="onLoad">更多...</van-button>
        <van-divider v-else>没有更多数据</van-divider>
    </van-tabs>
    <van-overlay :show="isLoading" >
      <view class="wrapper">
        <view class="block" catch:tap="noop">
          <van-loading size="24px" color="#ffffff">{{loadcontent}}</van-loading>
        </view>
      </view>
    </van-overlay>
  </div>
</template>

<script>
import { Divider, PullRefresh, Cell,CellGroup,Checkbox, Button, Dialog, CheckboxGroup, Card, SubmitBar, Toast, Tabs, Tab, Sticky, NavBar, Icon, Tag,  Overlay, Loading } from 'vant';
import { requestGetSaveList } from '../../api/api'

export default {
  components: {
    [Button.name]: Button,
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Sticky.name]: Sticky,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Tag.name]:Tag,
    [Dialog.name]: Dialog,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]:PullRefresh,
    [Divider.name]: Divider
  },
  data() {
    return {
      fromid: 1,
      toid: 5,
      listCount: 5,
      finished: false,
      loadcontent: '加载中...',
      isLoading: false,
      active: 0,
      lscurorder: [],
      lsorder: [],
      ordertype: [
        { id: 0, key: "all", title: "全部充值", icon: "wap-home" },
        { id: 1, key: "cash", title: "现金充值", icon: "logistics" },
        { id: 2, key: "subsidy", title: "补贴充值", icon: "logistics" },
        { id: 3, key: "thirdpay", title: "专用钱包", icon: "underway-o" }
      ]
    }
  },
  watch: {
    active(val) {
      console.log('获得的active发生变化：' + val);
      setTimeout(() => {
        this.fromid = 1
        this.toid = this.listCount
        this.lscurorder = []
        this.finished
        this.GetCurOrderInfo(val);
      }, 300);
    },
  },
  computed: {},
  mounted() {
    this.GetCurOrderInfo(0)

  },
  methods: {
    onLoad() {
      this.isLoading = true
      if (this.finished === true) return
      // console.log('加载数据了，')
      this.fromid = this.toid + 1
      this.toid = this.fromid + this.listCount
      this.GetCurOrderInfo(this.active)
    },
    onRefresh() {
      this.lscurorder = []
      this.fromid = 1
      this.toid =5
      this.finished = false
      this.GetCurOrderInfo(this.active)
    },
    async GetCurOrderInfo (ordertype) {
      // this.lscurorder = []
      this.isLoading = true
      const res = await requestGetSaveList({ accesstoken: this.$store.state.accesstoken, ordertype: ordertype, fromid: this.fromid, toid: this.toid})
      console.log(res)
      if (res.return_code === '0') {
        res.rows.forEach(info => {
          this.lscurorder.push(info)
        });
        this.isLoading = false
        if (res.rows.length < this.listCount){
          this.finished = true
          Toast({
              message: '无更多数据!',
              icon: 'fail',
          });
        }

      } else {
          Toast({
              message: res.return_message,
              icon: 'fail',
          });
          this.isLoading = false
      }
       
    },
    onClickLink() {
      Toast('去修改地址去');
    },
    formatPrice(price) {
      return (price / 1).toFixed(2);
    },
    onSubmit() {
      Toast('点击结算');
    }
  }
};
</script>

<style lang="less">
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}
</style>
